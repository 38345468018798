import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["messagesContainer"];

  connect() {
    setTimeout(() => {
      this.messagesContainerTarget.scrollTop = this.messagesContainerTarget.scrollHeight;
    }, 1000);
  }
}
