import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["value","safeCaseInput", "participantField", "safeCaseLineItem"];

  connect(){
    $(this.valueTarget).change(this.loadSafeCaseParticipantsFromEvent)

    if (this.valueTarget.value !== ''){
      this.loadSafeCaseParticipants(this.valueTarget.value)
    } else {
      console.log('no value')
    }
  }

  loadSafeCaseParticipantsFromEvent = (e) => {
    this.loadSafeCaseParticipants(e.target.value)
  }

  loadSafeCaseParticipants = (value) => {
    let url;
    if(window.location.pathname.includes('admin')){
      url = `/admin/safe_cases/${value}/participants`
    }else{
      url = `/safe_cases/${value}/participants`
    }

    $.ajax({
      url: url,
      method: 'GET',
      data: {
        id: value
      }
    }).done((result)=>{
      if (this.participantFieldTarget.classList.contains('d-none')){
        this.participantFieldTarget.classList.remove('d-none')
      }
      this.participantFieldTarget.innerHTML = result
    })
  }
}
