import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
  }

  toggle() {
    $("#messages-container").toggleClass("messages-container")
  }
}
