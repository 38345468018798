import { Controller } from "stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  connect() {
    let yesterday = new Date(new Date().setDate(new Date().getDate()-1));
    if (this.data.get("mode") == "single") {
      flatpickr(this.element, {
        dateFormat: "Y-m-d",
        allowInput: true,
        minDate: yesterday
      });
    } else {
      flatpickr(this.element, {
        mode: "multiple",
        dateFormat: "Y-m-d",
        allowInput: true,
        minDate: yesterday
      });
    }
  }
}
