import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

let debounce = require('lodash/debounce');

export default class extends Controller {
  static values = {
    delay: Number
  }

  initialize () {
    this.autosave = debounce(this.autosave, 2000).bind(this)
  }

  autosave () {
    Rails.fire(this.element, 'submit')
    $($(this.element).find('input,text')).addClass('bg-success')
    setTimeout(function() {
      $($(this.element).find('input,text')).removeClass('bg-success')
    }, 5000);
  }
}
