import "core-js/stable";
import "regenerator-runtime/runtime";

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require('bootstrap');
require("controllers");

const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

require('vendor/logged_out/jquery.waypoints.min');
require('vendor/logged_out/jquery.lockfixed.min');
require('vendor/logged_out/jquery.sticky.js');
require('vendor/logged_out/jquery.easing.min');
require('vendor/logged_out/hoverIntent.js');
require('src/logged-out-user');

import 'vendor/paper-dashboard-2-pro-html-v2.0.1/assets/css/bootstrap.min';
import 'stylesheets/font-awesome';
import 'stylesheets/logged-out-style';
