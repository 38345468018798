import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "links", "template" ]

  connect() {
  }

  add_association(event) {
    event.preventDefault();

    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime());
    this.linksTarget.insertAdjacentHTML('afterend', content);
  }

  destroy_association(event) {
    event.preventDefault();

    let wrapper = event.target.closest(".nested-fields");
    if (wrapper.dataset.newRecord == 'true') {
      wrapper.remove()
    } else {
      wrapper.querySelector(".destroy_association").value = 1
      wrapper.style.display = 'none'
    }
  }

  remove_association(event) {
    event.preventDefault();

    let wrapper = event.target.closest(".nested-fields");
    wrapper.querySelector(".remove_association").value = ""
    wrapper.style.display = 'none'
  }
}
