import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    $(this.element).on('shown.bs.modal', function () {
      $("[autofocus]").focus();
    });
    $(this.element).modal({keyboard: false});
  }
}
