import { Controller } from 'stimulus';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils.js';

export default class extends Controller {
  static targets = ["telInput", "hiddenPhoneInput"];

  connect() {
    var telInput = intlTelInput(this.telInputTarget,
      {
        formatOnInit: true,
        separateDialCode: true,
        utilsScript: "intl-tel-input/build/js/utils.js"
      }
    );

    telInput.setNumber($(this.hiddenPhoneInputTarget).val())


    $(this.telInputTarget).keyup(function(){
      var fullNumber = telInput.getNumber();
      $("#user_phone_number").val(fullNumber);
    })
  }
}
